<template>
  <html lang="en" class="notranslate" translate="no"><!-- All translators -->
  <head>
    <meta name="google" content="notranslate"/> <!-- Just for google -->
  </head>
  <div class="app">
    <Menu :list="menuList"/>
    <LoginPersonal />
    <Footer3/>
  </div>

  </html>
</template>

<script>
import('@/assets/css/stylesheet.css');

import Menu from '@/components/Menu.vue';
import Footer3 from '@/components/Footer3.vue';
import LoginPersonal from '@/components/Staff/LoginPersonal.vue';

import { ref, onBeforeMount } from 'vue';
import menuOptions  from "@/helpers/menu.js";

export default {
  name: 'Login',
  setup() {
    let menuList = ref([]);

    onBeforeMount(async () => {
        await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
          menuList.value = resp.data.FONCABSA;
      }).catch(err => {
          console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList
    }
  },
  components: {
    Menu,
    Footer3,
    LoginPersonal
  },
  async mounted() {
  },
  created() {
  },
  beforeMount() {
  }
}
</script>